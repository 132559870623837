@import "../../stylesheets/bootstrap";

.CheckedList {
  padding: 0;
  list-style: none;
  li {
    &:before {
      content: url('../../images/round-check-24px.svg');
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      margin-right: .5rem;
      vertical-align: text-top;
      margin-top: .25rem;
    }
    span {
      display: inline-block;
      width: calc(100% - 2rem);
      vertical-align: top;
      line-height: 2.25;
      font-size: 1rem;
    }
    @include media-breakpoint-down(xs) {
      &:before {
        margin-right: 1.5rem;
      }
      span {
        width: calc(100% - 3rem);
        font-size: 1.5rem;
      }
    }
  }  
}