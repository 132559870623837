.CookieBar {
  background-color: rgba(2, 5, 10, 0.8);
  transition: all .3s ease;
  small, a {
    color: white;
  }
  a:hover {
    color: white;
  }
  &.hidden {
    transform: translateY(100%);
    opacity: 0;
  }
}