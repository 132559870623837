@import 'bootstrap';
@import 'variables';

//@font-face {
//	font-family: 'Circular';
//	src: url('fonts/Circular-Book.eot?#iefix') format('embedded-opentype'),
//	url('fonts/Circular-Book.woff') format('woff'),
//	url('fonts/Circular-Book.ttf') format('truetype'),
//	url('fonts/Circular-Book.svg#Circular-Book') format('svg');
//	font-weight: normal;
//	font-style: normal;
//}
//
//
//@font-face {
//	font-family: 'Circular';
//	src: url('fonts/Circular-Bold.eot?#iefix') format('embedded-opentype'),
//	url('fonts/Circular-Bold.woff') format('woff'),
//	url('fonts/Circular-Bold.ttf') format('truetype'),
//	url('fonts/Circular-Bold.svg#Circular-Bold') format('svg');
//	font-weight: bold;
//	font-style: normal;
//}

html, body {
	font-size: 16px;
	font-family: 'Lato', sans-serif;
	overflow-x: hidden;
	@include media-breakpoint-down(md) {
		font-size: 14px;
	}
	@include media-breakpoint-down(xs) {
		font-size: 10px;
	}
}

body {
	@include media-breakpoint-down(xs) {
		font-size: 1.5rem;
		background-color: #E3E3E3;
	}

	&.disable-scroll {
		overflow: hidden;
	}
}

h1 {
	color: $main-color;
	font-size: 4rem;
	line-height: 1.2;
	letter-spacing: -0.01em;
	font-weight: 900;
	margin-bottom: 1.5rem;

	&.white {
		color: white;
	}
}

h2 {
	color: $main-color;
	font-size: 3rem;
	line-height: 1.2;
	letter-spacing: -0.01em;
	font-weight: 900;
	margin-bottom: 1.5rem;
	&.bigger {
		font-size: 4rem;
		font-weight: bold;
		line-height: 1;
	}
}

h3 {
	color: $main-color;
	font-size: 3rem;
	line-height: 1.125;
	letter-spacing: -0.005em;
	font-weight: 900;
	margin-bottom: 1.5rem;
	&.bigger {
		font-size: 4rem;
		line-height: 1;
		letter-spacing: -.01em;
		font-weight: 900;
		margin-bottom: 1.5rem;
	}
	&.smaller {
		font-size: 2.25rem;
		line-height: 0.83;
		margin-bottom: 1rem;
	}
}

h4 {
	color: $main-color;
	font-size: 2.25rem;
	line-height: 0.83;
	letter-spacing: -0.01em;
	font-weight: 900;
	margin-bottom: 1rem;
}

h5 {
	color: $main-color;
	font-size: 1.5rem;
	line-height: 1.25;
	letter-spacing: -0.01em;
	font-weight: 900;
}

h6 {
	color: $main-color;
	font-size: 1.125rem;
	line-height: 1.444;
	letter-spacing: 0.01em;
	margin-bottom: 1.5rem;
	font-weight: 900;
}

p {
	color: $main-color;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: -0.01em;
	margin-bottom: 0;

	&.large {
		font-size: 1.125rem;
	}

	&.introduction {
		font-size: 1.5rem;
		letter-spacing: -0.005em;
	}

	@include media-breakpoint-down(xs) {
		font-size: 1.5rem;
		&.large {
			font-size: 1.7rem;
		}
		&.introduction {
			font-size: 2rem;
		}
	}
}

a {
	color: $main-color;

	&:hover {
		color: $main-color;
		text-decoration: underline !important;
	}

	&.violet {
		color: $violet;
	}

	&.coral {
		color: $coral;
	}

	&.active {
		text-decoration: underline;
	}
}

body, p, a, button.btn, ul li span {
	@include media-breakpoint-down(xs) {
		font-size: 1.5rem;
	}
}

.coral {
	color: $coral;
}

article {
	p.introduction {
		opacity: .6;
	}
}

.big-link {
	padding: 3rem 2rem 5rem 2rem;
	position: relative;
	border-radius: .75rem;
	transition: all .3s ease;
	height: 100%;
	background-color: transparent;
	border: 2px solid #E3E3E3;

	.card-title {
		min-height: 3.5rem;
		margin-bottom: 1.5rem;
	}

	img {
		max-width: 3.5rem;
		max-height: 3.5rem;
		display: block;
		vertical-align: middle;
		margin-bottom: 2rem;
		@include media-breakpoint-down(xs) {
			width: 5rem;
			height: 5rem;
			max-width: 5rem;
			max-height: 5rem;
		}
	}

	h4 {
		display: block;
		font-size: 1.5rem;
	}

	.placeholder {
		transition: all .3s ease;
	}

	.btn-violet {
		position: absolute;
		bottom: 1rem;
	}

	&:hover {
		background-color: white;
		box-shadow: 6px 6px 20px rgba(137, 137, 137, 0.16);
		border-color: transparent;

		a {
			text-decoration: none !important;
		}

		.placeholder {
			transform: scale(1.05);
		}
	}

	@include media-breakpoint-down(xs) {
		padding-bottom: 8rem;
	}
}

.row {
	flex: 1;
}

b {
	font-weight: 900;
}

.uc {
	text-transform: uppercase;
}

.mb-xxl {
	margin-bottom: 10rem;
}

.mt-xxl {
	margin-top: 10rem;
}

.my-xxl {
	margin-top: 10rem;
	margin-bottom: 10rem;
}

.mb-xl {
	margin-bottom: 8rem;
}

.mt-xl {
	margin-top: 8rem;
}

.my-xl {
	margin-top: 8rem;
	margin-bottom: 8rem;
}

.mb-l {
	margin-bottom: 6rem;
}

.mt-l {
	margin-top: 6rem;
}

.my-l {
	margin-top: 6rem;
	margin-bottom: 6rem;
}

.mb-m {
	margin-bottom: 5rem;
}

.mt-m {
	margin-top: 5rem;
}

.my-m {
	margin-top: 5rem;
	margin-bottom: 5rem;
}

.mb-sm {
	margin-bottom: 3rem;
}

.mt-sm {
	margin-top: 3rem;
}

.my-sm {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.mb-s {
	margin-bottom: 2.5rem;
}

.mt-s {
	margin-top: 2.5rem;
}

.my-s {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
}

.mb-xs {
	margin-bottom: 2rem;
}

.mt-xs {
	margin-top: 2rem;
}

.my-xs {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.pb-xxl {
	padding-bottom: 10rem;
}

.pt-xxl {
	padding-top: 10rem;
}

.py-xxl {
	padding-top: 10rem;
	padding-bottom: 10rem;
}

.pb-xl {
	padding-bottom: 8rem;
}

.pt-xl {
	padding-top: 8rem;
}

.py-xl {
	padding-top: 8rem;
	padding-bottom: 8rem;
}

.pb-l {
	padding-bottom: 6rem;
}

.pt-l {
	padding-top: 6rem;
}

.py-l {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.pb-m {
	padding-bottom: 5rem;
}

.pt-m {
	padding-top: 5rem;
}

.py-m {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.pb-sm {
	padding-bottom: 3rem;
}

.pt-sm {
	padding-top: 3rem;
}

.py-sm {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.pb-s {
	padding-bottom: 2.5rem;
}

.pt-s {
	padding-top: 2.5rem;
}

.py-s {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
}

.pb-xs {
	padding-bottom: 2rem;
}

.pt-xs {
	padding-top: 2rem;
}

.py-xs {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

button.btn {
	min-height: 3rem;
	min-width: 8.25rem;
	border-radius: 1.5rem;
	transition: .3s all ease;
	padding: 0 2rem;
	font-weight: bold;
	@include media-breakpoint-down(xs) {
		min-height: 5rem;
		border-radius: 2.5rem;
	}
}

button.btn.btn-primary, button.btn.btn-primary:not(:disabled):not(.disabled):active {
	background-color: $violet;
	border: 2px solid $violet;

	&:focus, &:hover {
		background-color: white;
		color: $violet;
	}

	&:focus {
		box-shadow: none;
	}
}

button.btn.btn-outline-dark, button.btn.btn-outline-light {
	border-radius: 4rem;
	min-height: 3rem;
	min-width: 9.875rem;
}

button.btn.btn-coral {
	background-color: $coral;
	color: white;
	border: 2px solid $coral;

	&:focus, &:hover {
		box-shadow: none;
		background-color: white;
		color: $coral;
	}
}

.Index, .Expertise, .Work, .Culture, .Careers, .CareersItem, .Contact, .Research, .ResearchTechniques, .ResearchProcess, .ResearchDesign, .GatheringData, .Analysis, .MethodLinking, .BecomeARespondent, .OrderResearch, .ElearningResearch, .EcommerceResearch, .DigitalResearch, .AtlBtlResearch, .Blog, .BlogItem, .Laboratory {
	padding-top: 7rem;
}

.OrderResearch, .BecomeARespondent {
	padding-top: 10rem;
}

.partners, .open-positions {
	border-bottom: 2px solid #f2f3f5;
}

.Work {
	.Jumbotron {
		background-color: #f2f3f5;

		&:nth-child(even) {
			background-color: #dee0e3;
		}
	}
}

.placeholder {
	background-color: #f2f3f5;
	border-radius: .75rem;
	padding-bottom: 100%;
	background-position: center center;
	background-size: cover;

	&-horizontal {
		padding-bottom: 40%;
	}
}

.center-box {
	align-self: center;
	padding: 3rem;
}

.advantages {
	p.introduction {
		opacity: .6;
	}

	.placeholder {
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.row {
		.col-12.col-lg-6 {
			margin-bottom: 1.5rem;
		}

		&:nth-child(even) {
			@include media-breakpoint-up(lg) {
				.col-12.col-lg-6 {
					&:first-child {
						order: 2
					}

					&:last-child {
						order: 1;
					}
				}
			}
		}
	}
}

.recruitment-process {
	background-color: #f2f3f5;

	.placeholder {
		background-color: #dee0e3;
	}
}

figure {
	width: 100%;
	max-width: 10rem;
	margin: 0 auto;
}

.map {
	height: 40rem;
	background-image: url('../images/Map Background@2x.png');
	background-size: cover;
	background-position: center center;
}

.case-studies {
	background-color: #f2f3f5;
}

.counter {
	background-color: $violet;
	background-image: url('../images/laboratory/counter-bg.svg');
	background-size: 100% auto;
	background-position: left top;
}

.WorkItem {
	article {
		h6 {
			margin-bottom: 1rem;
		}

		h3 {
			margin-bottom: 3rem;
		}

		p {
			font-size: 1.3125rem;
			line-height: 1.5;
			margin-bottom: 2rem;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.img-fluid {
			margin-bottom: 3rem;
		}
	}
}

.banner {
	background-color: #f2f3f5;
	padding-bottom: 53%;
}

.CareersItem {
	h4 {
		margin-bottom: 1rem;
	}

	&-header {
		border-bottom: 2px solid #F2F3F5;
	}
}

.Expertise, .Index {
	.masonry {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr 1.5rem 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
		grid-gap: 1.5rem 1.5rem;
		justify-items: stretch;
		grid-template-areas: ". card1" "card2 card1" "card2 card3" "card4 card3" "card4 .";
		@include media-breakpoint-down(md) {
			-ms-grid-columns: 1fr;
			grid-template-columns: 1fr;
			grid-template-areas: "card1" "card2" "card3" "card4";
		}

		.Card {
			&:nth-child(1) {
				-ms-grid-row: 1;
				-ms-grid-row-span: 3;
				-ms-grid-column: 3;
				grid-area: card1;
			}

			&:nth-child(2) {
				-ms-grid-row: 3;
				-ms-grid-row-span: 3;
				-ms-grid-column: 1;
				grid-area: card2;
			}

			&:nth-child(3) {
				-ms-grid-row: 5;
				-ms-grid-row-span: 3;
				-ms-grid-column: 3;
				grid-area: card3;
			}

			&:nth-child(4) {
				-ms-grid-row: 7;
				-ms-grid-row-span: 3;
				-ms-grid-column: 1;
				grid-area: card4;
			}

			@include media-breakpoint-down(md) {
				&:nth-child(1) {
					-ms-grid-row: 1;
					-ms-grid-column: 1;
					grid-area: card1;
				}
				&:nth-child(2) {
					-ms-grid-row: 2;
					-ms-grid-column: 1;
					grid-area: card2;
				}
				&:nth-child(3) {
					-ms-grid-row: 3;
					-ms-grid-column: 1;
					grid-area: card3;
				}
				&:nth-child(4) {
					-ms-grid-row: 4;
					-ms-grid-column: 1;
					grid-area: card4;
				}
			}
		}
	}

	.partners {
		.container:nth-child(2) {
			.row {
				flex-direction: row-reverse;
			}
		}

		.masonry {
			display: -ms-grid;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			-ms-grid-rows: auto;
			grid-template-rows: auto;
			grid-gap: 1.5rem 1.5rem;
			justify-items: stretch;
			grid-template-areas: ". card2 . card4" "card1 card2 card3 card4" "card1 . card3 .";

			.LogoBox {
				&:nth-child(1) {
					grid-area: card1;
				}

				&:nth-child(2) {
					grid-area: card2;
				}

				&:nth-child(3) {
					grid-area: card3;
				}

				&:nth-child(4) {
					grid-area: card4;
				}
			}

			@include media-breakpoint-down(lg) {
				-ms-grid-columns: 1fr 1.5rem 1fr;
				grid-template-columns: 1fr 1fr;
				grid-template-areas: ". card1" "card2 card1" "card2 card3" "card4 card3" "card4 .";
			}
			@include media-breakpoint-down(md) {
				-ms-grid-columns: 1fr;
				grid-template-columns: 1fr;
				grid-template-areas: "card1" "card2" "card3" "card4";
			}
		}
	}
}

.award-content {
	h1 {
		font-size: 9rem;
		padding: 1rem 0;
		line-height: 1.2
	}

}

.ExpertiseItem {
	.masonry {
		.placeholder {
			width: 10rem;
			height: 10rem;
			padding-bottom: 0;
			border-radius: 50%;
			margin-bottom: 4rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.benefits {
	.placeholder {
		width: 5rem;
		height: 5rem;
		padding-bottom: 0;
		border-radius: 50%;
		margin-bottom: 1.5rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.ExpertiseItem, .ResearchTechniques {
	.masonry {
		display: -ms-grid;
		display: grid;
		-ms-grid-columns: 1fr 1.5rem 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
		grid-gap: 1.5rem 1.5rem;
		justify-items: stretch;
		grid-template-areas: "card1 ." "card1 card2" "card3 card2" "card3 card4" "card5 card4" "card5 card6" ". card6";
		@include media-breakpoint-down(md) {
			-ms-grid-columns: 1fr;
			grid-template-columns: 1fr;
			grid-template-areas: "card1" "card2" "card3" "card4" "card5" "card6";
		}

		.LogoBox {
			&:nth-child(1) {
				-ms-grid-row: 1;
				-ms-grid-row-span: 3;
				-ms-grid-column: 1;
				grid-area: card1;
			}

			&:nth-child(2) {
				-ms-grid-row: 3;
				-ms-grid-row-span: 3;
				-ms-grid-column: 3;
				grid-area: card2;
			}

			&:nth-child(3) {
				-ms-grid-row: 5;
				-ms-grid-row-span: 3;
				-ms-grid-column: 1;
				grid-area: card3;
			}

			&:nth-child(4) {
				-ms-grid-row: 7;
				-ms-grid-row-span: 3;
				-ms-grid-column: 3;
				grid-area: card4;
			}

			&:nth-child(5) {
				-ms-grid-row: 9;
				-ms-grid-row-span: 3;
				-ms-grid-column: 1;
				grid-area: card5;
			}

			&:nth-child(6) {
				-ms-grid-row: 11;
				-ms-grid-row-span: 3;
				-ms-grid-column: 3;
				grid-area: card6;
			}

			@include media-breakpoint-down(md) {
				&:nth-child(1) {
					-ms-grid-row: 1;
					-ms-grid-column: 1;
					grid-area: card1;
				}
				&:nth-child(2) {
					-ms-grid-row: 2;
					-ms-grid-column: 1;
					grid-area: card2;
				}
				&:nth-child(3) {
					-ms-grid-row: 3;
					-ms-grid-column: 1;
					grid-area: card3;
				}
				&:nth-child(4) {
					-ms-grid-row: 4;
					-ms-grid-column: 1;
					grid-area: card4;
				}
				&:nth-child(5) {
					-ms-grid-row: 5;
					-ms-grid-column: 1;
					grid-area: card5;
				}
				&:nth-child(6) {
					-ms-grid-row: 6;
					-ms-grid-column: 1;
					grid-area: card6;
				}
			}
		}
	}
}

.ResearchTechniques {
	.LogoBox {
		padding: 8rem 4rem;
	}
}

i.material-icons {
	vertical-align: middle;
}

.cards {
	.Card {
		margin-bottom: 2rem;
	}
}

.Index .Jumbotron {
	background-color: transparent;

	.row .row .col-12:first-child {
		position: relative;
	}

	h2 {
		font-size: 4rem;
	}
}

@keyframes blob {
	0%, 50%, 100% {
		transform: scale(0.1);
	}
	25% {
		transform: scale(0.6);
	}
}

.dots {
	.dot {
		position: absolute;
		top: 50%;
		background-color: black;
		border-radius: 50%;
		width: 6rem;
		height: 6rem;
		animation: blob 2s ease infinite;
		//filter: blur(12px) brightness(1.4) contrast(100);
		transform: scale(0.4);

		&:nth-child(1) {
			left: 20%;
			animation-delay: 0s;
		}

		&:nth-child(2) {
			left: 22%;
			animation-delay: 0.25s;
		}

		&:nth-child(3) {
			left: 24%;
			animation-delay: 0.5s;
		}

		&:nth-child(4) {
			left: 26%;
			animation-delay: 0.75s;
		}
	}
}

.video-bg {
	.Jumbotron {
		background-color: transparent;

		* {
			color: white;
			border-color: white;
		}

		.arrow-down {
			display: none;

			svg {
				.cls-1 {
					fill: white;
				}
			}
		}
	}
}

.Research > .ResearchTechniques {
	padding-top: 0;
}

.Navigation {
	.collapse.navbar-collapse {
		@include media-breakpoint-down(md) {
			display: none !important;
		}
	}
}

.research-section {
	.Card {
		transition: all .3s ease;
		box-shadow: none;
		border: 2px solid #E3E3E3;

		&:hover {
			//background-color: #FAFAFA;
			border-color: transparent;
			box-shadow: 6px 6px 20px rgba(137, 137, 137, 0.16)
		}

		.dot-bg {
			display: none;
		}

		.card-image {
			width: 18.75rem;
			height: 18.75rem;
			max-width: none;
			max-height: none;
			border-radius: 50%;
			margin: 0 auto;
			display: block;
			//overflow: hidden;
			position: relative;
			z-index: 1;
			margin-bottom: 4rem;

			img {
				width: 18.75rem;
				height: 18.75rem;
				max-width: 100%;
				object-fit: contain;
				//border-radius: 50%;
			}
		}
	}

	.col-12 {
		//&:first-child {
		//  .card-image {
		//    &:before {
		//      content: '';
		//      width: 10rem;
		//      height: 10rem;
		//      border-radius: 50%;
		//      background-color: #FF868C;
		//      position: absolute;
		//      right: -2rem;
		//      top: -1.5rem;
		//      z-index: -1;
		//    }
		//    &:after {
		//      content: '';
		//      width: 2rem;
		//      height: 2rem;
		//      border-radius: 50%;
		//      background-color: #937EF8;
		//      position: absolute;
		//      left: -4rem;
		//      bottom: 3rem;
		//    }
		//  }
		//}
		//&:last-child {
		//  .card-image {
		//    &:before {
		//      content: '';
		//      width: 4.125rem;
		//      height: 4.125rem;
		//      border-radius: 50%;
		//      background-color: #FFC104;
		//      position: absolute;
		//      right: -2rem;
		//      bottom: 3rem;
		//    }
		//    &:after {
		//      content: '';
		//      width: 2rem;
		//      height: 2rem;
		//      border-radius: 50%;
		//      background-color: $coral;
		//      position: absolute;
		//      right: -3rem;
		//      top: 3rem;
		//    }
		//  }
		//}
	}
}

.rounded-bottom-bg {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 130%;
	background-color: white;
	height: 25rem;
	background-position: right top;
	background-repeat: no-repeat;
	background-size: 80% auto;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: rgb(250, 250, 250);
		height: 85%;
		border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;
		background-image: url('../images/circles.svg');
		background-position: right top;
		background-repeat: no-repeat;
		background-size: 80% auto;
	}
}

.overflow-hidden {
	overflow: hidden;
}

.testimonials {
	position: relative;
	background-image: url('../images/circles-grey.svg');
	background-position: right -100% bottom 10%;
	background-repeat: no-repeat;
	background-size: 80% auto;

	.dot {
		right: 15%;
		top: 0;
	}
}

.Index, .Laboratory {
	.dot {
		border-radius: 50%;
		position: absolute;

		&-coral {
			background-color: $coral;
		}

		&-violet {
			background-color: #937EF8;
		}

		&-yellow {
			background-color: #FFC104;
		}

		&-black {
			background-color: black;
		}

		&-xs {
			width: 1.5rem;
			height: 1.5rem;
		}

		&-s {
			width: 2rem;
			height: 2rem;
		}
	}
}

.Laboratory {
	.Jumbotron {
		background-image: url('../images/laboratory/banner-bg.svg');
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center center;
	}

	.counter {
		.col-12 {
			&:after {
				content: '';
				width: 2px;
				height: 80%;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				background-color: rgba(255, 255, 255, 0.75);
				display: block;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			&:nth-child(3), &:nth-child(5) {
				&:after {
					display: none;
				}
			}
		}
	}

	.dot {
		left: 0;
		bottom: 0;

		&-coral {
			left: auto;
			right: 20%;
			bottom: 1rem;
		}
	}
}

.LetsWork {
	border-radius: .75rem;
}

.techniques {
	.LogoBox {
		background-color: transparent;
		padding: 4.875rem 4rem;
		border: 2px solid #fafafa;

		p.large {
			opacity: .75;
		}
	}
}

.dot-bg {
	position: absolute;
	width: 25%;
	height: 30%;
	left: 0;
	bottom: 0;
	background-image: url('../images/dot-bg.svg');
}

.research-offer {
	p.large {
		opacity: .6;
	}

	p.research-offer-number {
		font-size: 5rem;
		font-weight: bold;
		color: #f2f2f2;
		line-height: 1.5;
		letter-spacing: -.4px;
	}
}

.inverse {
	.ContactForm {
		border-radius: .75rem;
		background-color: $violet;
		padding: 5rem 3rem;
		background-image: url('../images/contact-form/bg.svg');
		background-position: bottom 5% right -15%;
		background-repeat: no-repeat;

		* {
			color: white;
		}

		.form-control {
			background-color: transparent;
			border-color: rgba(255, 255, 255, .6);

			&:valid {
				&:not(.empty) {
					border-color: rgba(255, 255, 255, 1);
				}
			}

			&:focus {
				border-color: rgba(255, 255, 255, 1);
			}
		}

		textarea {
			border: 2px solid;
			border-radius: .75rem;
			padding: 1rem;
		}

		button.btn.btn-primary {
			background-color: white;
			color: $violet;

			&:hover, &:focus {
				border-color: white;
				background-color: transparent;
				color: white;
			}
		}
	}
}

.secondary-nav {
	a {
		//display: block;
		img {
			//display: inline-block;
			//max-width: 3rem;
			//height: 3rem;
			//vertical-align: middle;
			//margin-right: 1rem;
		}
	}
}

.next-section {
	border-top: 1px solid #E3E3E3;

	a {
		&:hover {
			text-decoration: none !important;

			* {
				text-decoration: none !important;
			}

			.rounded-arrow {
				transform: translateX(0.5rem);
			}
		}
	}
}

.rounded-arrow {
	width: 4.75rem;
	height: 4.75rem;
	border-radius: 50%;
	border: 2px solid black;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all .3s ease;

	&:after {
		content: '';
		background-image: url('../images/arrow-right.svg');
		width: 45%;
		height: 45%;
		object-fit: cover;
		background-position: center center;
		background-size: 100% auto;
	}
}

.line {
	width: 100%;
	height: 1px;
	background-color: #E3E3E3;
}

article.post {
	padding: 3rem 0;
}

.anim {
	opacity: 0;
	transition: all .5s ease;

	&.fade-in-top {
		transform: translateY(4rem);
	}

	&.active {
		opacity: 1;

		&.fade-in-top {
			transform: translateY(0);
		}
	}

	@for $i from 0 through 5 {
		.delay-#{$i} {
			transition-delay: #{$i * 100}ms;
		}
	}
}

@include media-breakpoint-down(sm) {
	.Jumbotron {
		background-image: none !important;
	}
}

.secondary-nav.fixed-top {
	top: 7rem;

	&.navbar-hidden {
		top: 5.5rem;
		@include media-breakpoint-down(xs) {
			top: 8rem;
		}
	}
}

#video-section-1 {
	.VideoBg {
		&:after {
			content: '';
		}
	}
}

.is-nav-open {
	overflow: hidden;
	.secondary-nav.fixed-top {
		z-index: -1;
	}
}

.secondary-nav.fixed-top {
	@include media-breakpoint-down(xs) {
		.container {
			width: auto;
			.row {
				flex-wrap: nowrap;
				flex-basis: auto;
				flex-shrink: 1;
				flex-grow: 0;
				.col-12 {
					width: auto !important;
					//flex-wrap: nowrap;
					flex-basis: auto;
					flex-shrink: 1;
					flex-grow: 0;
					max-width: none;
					overflow-x: auto;
					a {
						display: block;
						min-width: 45%;
						margin-right: 1rem;
					}
				}
			}
		}
	}
}

//------------------Blog Excerpt at main page------

.blog-excerpt {
	a:hover {
		text-decoration: none !important;
	}
	.post-card {
		height: 653px;
		border: 2px solid #E3E3E3;
		border-radius: .75rem;
		overflow: hidden;
		transition: all .3s ease;
		&:hover {
			background-color: white;
			box-shadow: 6px 6px 20px rgba(137, 137, 137, 0.16);
			border-color: transparent;
		}

		.post-img {
			//border: 2px solid red;
			width: 100%;
			object-fit: cover;
			border-radius: 3px;
			max-height: 280px;
		}

		.category {
			text-transform: uppercase;
			color: #FB555D;
			font-size: 1rem;
			font-weight: bold;
			line-height: 1.625rem;
			letter-spacing: 0.08rem;
			padding: 0 2rem;
			margin-bottom: 0;
		}
		.card-title {
			font-size: 1.5rem;
			padding: 0 2rem;
		}

		.author {
			padding: 0 2rem 1rem 2rem;
			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}

			.author-name {
				font-size: 1rem;
				text-transform: uppercase;
				line-height: 1.625rem;
				letter-spacing: 0.08rem;
			}

			.author-company {
				color: #797979;

			}
		}
	}
}

//-----------------End of Blog Excerpt-------------


//-------------------Blog---------------

.Blog {
	a:hover {
		text-decoration: none !important;;
	}

	.text-container {
		h4 {
			text-transform: uppercase;
			color: #FB555D;
			font-size: 1.125rem;
			font-weight: bold;
			line-height: 1.625rem;
			letter-spacing: 0.08rem;
		}

		h2 {
			font-size: 3rem;
			font-weight: bold;
		}

		p {
			font-size: 1.5rem;
			line-height: 2.25rem;
		}

		.author {
			margin-top: 20px;

			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}

			.author-name {
				font-size: 1rem;
				text-transform: uppercase;
				line-height: 1.625rem;
				letter-spacing: 0.08rem;
			}

			.author-company {
				color: #797979;

			}
		}
	}

	.posts {
		.post-card {
			min-height: 625px;
			@include media-breakpoint-down(xs) {
				height: auto;
				min-height: 0;
			}
		}

		.card-img-top {
			height: 15rem;
			object-fit: cover;
		}

		.category {
			text-transform: uppercase;
			color: #FB555D;
			font-size: 1.125rem;
			font-weight: bold;
			line-height: 1.625rem;
			letter-spacing: 0.08rem;
		}

		.card-text {
			font-size: 1.125rem;
			line-height: 1.6rem;
			letter-spacing: -0.01rem;
			font-weight: normal;
		}

		.author {
			@include media-breakpoint-down(xs) {
				margin-top: 2rem !important;
			}
			img {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}

			.author-name {
				font-size: 1rem;
				text-transform: uppercase;
				line-height: 1.625rem;
				letter-spacing: 0.08rem;
			}

			.author-company {
				color: #797979;

			}
		}
	}
}

.BlogItem {
	h4 {
		text-transform: uppercase;
		color: #FB555D;
		font-size: 1.125rem;
		font-weight: bold;
		line-height: 1.625rem;
		letter-spacing: 0.08rem;
	}

	.author {
		img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}

		.author-name {
			font-size: 1rem;
			text-transform: uppercase;
			line-height: 1.625rem;
			letter-spacing: 0.08rem;
		}

		.author-company {
			color: #797979;
		}
	}

	.text-content {
		p {
			font-size: 1.5rem;
			line-height: 2.25rem;
			letter-spacing: -0.01rem;
			font-weight: normal;
			margin-bottom: 1rem;
		}

		img {
			margin-bottom: 1rem;
			max-width: 100%;
			height: auto;
		}
	}
}

@media(max-width: 1440px) {
	.Blog {
		.text-container {
			height: 350px;
			margin-top: 2rem;
		}
	}
}

@media(max-width: 993px) {
	.Blog {
		.text-container {
			h4 {
				font-size: 1.125rem;
				font-weight: bold;
				line-height: 1.625rem;
				letter-spacing: 0.08rem;
			}

			h2 {
				font-size: 1.5rem;
				font-weight: bold;
			}

			p {
				font-size: 1rem;
				line-height: 2.25rem;
			}
		}
	}
}


@media(max-width: 576px) {
	.Blog {
		.text-container {
			height: auto;

			h4 {
				font-size: 1.125rem;
				font-weight: bold;
				line-height: 1.625rem;
				letter-spacing: 0.08rem;
			}

			h2 {
				font-size: 1.5rem;
				font-weight: bold;
			}

			p {
				font-size: 1.5rem;
				line-height: 2.25rem;
				//margin-bottom: 10px;
			}
		}

		.posts {
			.post-card {
				height: auto;
			}
		}
	}
}


//------------------End of Blog---------

#cookiescript_injected {
	#cookiescript_accept {
		//background-color: #FB555D;
	}
	#cookiescript_copyright {
		display: none;
	}
}

.EcommerceResearch {
	li {
		opacity: .6;
	}
}
