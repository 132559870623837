@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1440px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1344px
);

$grid-gutter-width:           24px;

@import "~bootstrap/scss/mixins";

@import "../../stylesheets/_variables.scss";

menu.Menu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #050c1a;
  margin: 0;
  overflow: hidden;
  z-index: -1000;
  opacity: 0;
  transition: all .3s ease;
  display: none;
  button.btn.btn-outline-coral {
    border-color: $coral;
    color: $coral;
    &:hover {
      background-color: $coral;
      color: white;
    }
  }
  @include media-breakpoint-down(md) {
    padding-left: 0;
  }
  ul {
    list-style: none;
    padding-left: 0;
    a {
      opacity: 0.5;
      transition: all .3s ease;
      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }
  }
  @media all and (max-width: 340px) {
    h1 {
      font-size: 3rem;
    }
  }
  &.open {
    z-index: 1;
    opacity: 1;
    display: flex;
  }
  .fixed-bottom {
    button.btn-dark {
      width: 50%;
      background: rgba(128, 128, 128, 0.2);
      background: -moz-linear-gradient(top, rgba(255,255,255,0.2) 0%, rgba(128,128,128,0.2) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.2)), color-stop(100%, rgba(128,128,128,0.2)));
      background: -webkit-linear-gradient(top, rgba(255,255,255,0.2) 0%, rgba(128,128,128,0.2) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0.2) 0%, rgba(128,128,128,0.2) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0.2) 0%, rgba(128,128,128,0.2) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0.2) 0%, rgba(128,128,128,0.2) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#808080', GradientType=0 );
      background-size: 100% 300%;
      background-position: bottom center;
      color: rgba(255, 255, 255, 0.4);
      height: 8rem;
      border-radius: 0;
      border: none;
      display: inline-block;
      transition: background-position .3s ease;
      &:nth-child(1) {
        border-right: 1px solid black;
      }
      &:nth-child(2) {
        border-left: 1px solid black;
      }
      &:hover {
        background-position: top center;
      }
    }
  }
}