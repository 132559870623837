@import '../../stylesheets/variables';

.secondary-nav {
  background-color: white;
  transition: .3s all ease;
  &.fixed-top {
    top:5rem;
  }
  a {
    display: inline-block;
    margin-right: 5rem;
    opacity: .6;
    transition: all .3s ease;
    &:last-child {
      margin-right: 0;
    }
    img {
      display: inline-block;
      max-width: 3rem;
      height: 3rem;
      vertical-align: middle;
      margin-right: 1rem;
    }
    .bullet {
      display: inline-block;
      width: .4rem;
      height: .4rem;
      border-radius: 50%;
      transition: all .3s ease;
      background-color: #000;
      margin-right: 1rem;
    }
    b {
      display: inline-block;
      max-width: 13rem;
      vertical-align: middle;
    }
    &.active, &:hover {
      opacity: 1;
      text-decoration: underline !important;
      .bullet {
        background-color: $coral;
      }
      b {
        text-decoration: underline !important;
        text-align: left !important;
      }
    }
  }
  &.navbar-hidden {
    transform: translateY(-100%);
    border-bottom: 1px solid #e3e3e3;
  }
}