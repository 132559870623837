@import '../../stylesheets/variables';

.Accordion {

  .AccordionItem {
    padding: 1.875rem 3rem 1.875rem 1.25rem;
    border-radius: .75rem;
    transition: all .3s ease;
    cursor: pointer;
    position: relative;
    margin-bottom: 1rem;
    .accordion-number {
      display: inline-flex;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      border: 2px solid $main-color;
      justify-content: center;
      align-items: center;
      font-size: 1.125rem;
      margin-right: 1.5rem;
      vertical-align: top;
      color: $main-color;
      font-weight: bold;
    }
    .accordion-content {
      display: inline-block;
      width: calc(100% - 4rem);
      height: 100%;
    }
    &:after {
      position: absolute;
      content: '';
      background-position: center center;
      background-image: url('../../images/arrow-down.svg');
      right: 1rem;
      top: 1.875rem;
      width: 1.5rem;
      height: 1.5rem;
      transition: all .3s ease;
      transform-origin: center center;
    }
    &.open, &:hover {
      background-color: #FAFAFA;
    }
    &.open {
      background-color: #A693FF;
      &:after {
        transform: rotate(180deg);
      }
    }
  }
  &.coral {
    .AccordionItem {
      &.open {
        background-color: #FF868C;
      }
    }
  }
}