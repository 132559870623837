.VideoBg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:after {
    //content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(86, 3, 173, 0.4);
    background-image: url('../../images/index/video-bg-overlay.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 75% auto;
  }
}