@import '../../stylesheets/variables';

.LetsWork {

  //background: rgba(203,35,108,1);
  //background: -moz-linear-gradient(-60deg, rgba(203,35,108,1) 0%, rgba(76,36,109,1) 100%);
  //background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(203,35,108,1)), color-stop(100%, rgba(76,36,109,1)));
  //background: -webkit-linear-gradient(-60deg, rgba(203,35,108,1) 0%, rgba(76,36,109,1) 100%);
  //background: -o-linear-gradient(-60deg, rgba(203,35,108,1) 0%, rgba(76,36,109,1) 100%);
  //background: -ms-linear-gradient(-60deg, rgba(203,35,108,1) 0%, rgba(76,36,109,1) 100%);
  //background: linear-gradient(150deg, rgba(203,35,108,1) 0%, rgba(76,36,109,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb236c', endColorstr='#4c246d', GradientType=1 );
  background-color: $violet;
  background-repeat: no-repeat;
  background-position: right 20% center;
  background-size: auto 80%;
  background-image: url('../../images/letswork/bg.svg');
  * {
    color: white;
  }
}