.Jumbotron {
  @keyframes arrow-move {
    0%, 100% {
      transform: translate(-50%, 0);
    }
    75% {
      transform: translate(-50%, 0.5rem);
    }
  }
  min-height: calc(100vh - 7rem);
  //background-color: #dddddd;
  .arrow-down {
    position: absolute;
    left: 50%;
    bottom: 3rem;
    transform: translateX(-50%);
    animation: arrow-move 1s infinite ease;
  }
  .jumbotron-fluid {
    width: 100%;
  }
}