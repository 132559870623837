@import '../../stylesheets/bootstrap';
@import '../../stylesheets/variables';

.EuBanner {
  background-color: white;
  position: fixed;
  width: calc(95% - 8rem);
  border-radius: 0 0 0 .75rem;
  top: 10rem;
  right: 0;
  transform: translateX(101%);
  transition: transform .3s ease;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.16));
  padding: 1.5rem;
  &.open {
    transform: translateX(0);
  }
  .clicker {
    position: absolute;
    left: -8rem;
    top:0;
    content: url('../../images/eu.jpg');
    width: 8rem;
    border-radius: .75rem 0 0 .75rem;
    object-fit: contain;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }
  @include media-breakpoint-up(md) {
    width: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 40%;
  }

  @media all and (min-width: 1500px) {
    width: 25%;
  }
  .logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
      flex-basis: 100%;
      //@include media-breakpoint-up(lg) {
      //  flex-basis: 50%;
      //}
    }
    img {
      max-height: 6rem;
      max-width: 14rem;
      display: block;
      margin: .25rem auto;
    }
    div:nth-child(3) img {
      max-width: 10rem;
    }
    @include media-breakpoint-up(lg) {
      img {
        max-height: 6rem;
        max-width: 10rem;
        display: block;
        margin: .5rem auto;
      }
      div:nth-child(3) img {
        max-width: 8.5rem;
      }
    }
  }
  &:hover:not(.open) {
    transform:translateX(95%)
  }
}