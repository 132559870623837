.ContactBox {
  p.large {
    opacity: .75;
  }
  a {
    color: #050c1a;
  }
  .placeholder {
    border-radius: 50%;
    background-color: #937EF8;
    background-size: cover;
    background-position: center center;
  }
}