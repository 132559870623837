@import "../../stylesheets/bootstrap";
@import '../../stylesheets/variables';

.Navigation {
  transition: all .3s ease;
  position: relative;
  //z-index: 1031;
  .nav-outer {
    padding-left: 2rem;
    padding-right: 2rem;
    //box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.16);
    background-color: white;
    border-bottom: 1px solid #E3E3E3;
    transition: all .3s ease;

    //background: rgba(255,255,255,1);
    //background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.78) 30%, rgba(255,255,255,0) 100%);
    //background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(30%, rgba(255,255,255,0.78)), color-stop(100%, rgba(255,255,255,0)));
    //background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.78) 30%, rgba(255,255,255,0) 100%);
    //background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.78) 30%, rgba(255,255,255,0) 100%);
    //background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0.78) 30%, rgba(255,255,255,0) 100%);
    //background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0.78) 30%, rgba(255,255,255,0) 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );

    @include media-breakpoint-down(xs) {
      height: 8rem;
    }

  }
  .collapse.navbar-collapse {
    display: inline-flex !important;
    margin-left: 2rem;
  }
  .nav {
    //display: inline-flex;
    //margin-left: 2rem;
  }
  .nav-item a {
    font-weight: bold;
  }
  .right-part {
    flex-grow: initial;
    a, button.btn.btn-coral, .language {
      font-weight: bold;
    }
    a {
      color: $coral !important;
      cursor: pointer;
    }
  }
  .language {
    display: inline-block;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    i.material-icons {
      margin-right: .5rem;
    }
    a {
      color: $main-color !important;
    }
  }
  .contact-us {
    display: inline-block;
    padding-left: 1.5rem;
    //border-left: 1px solid #C6C9CF;
    i.material-icons {
      margin-right: .5rem;
    }
  }
  .logo {
    cursor: pointer;
    display: inline-block;
  }
  .hamburger {
    width: 3rem;
    height: 3rem;
    display: inline-block;
    cursor: pointer;
    margin-right: 1.5rem;
    vertical-align: middle;
    padding: initial;
    .hamburger-inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 100%;
      align-items: stretch;
    }
    span {
      display: block;
      width: 100%;
      height: .25rem;
      background-color: #050C1A;
      margin-bottom: .75rem;
      transition: all .3s ease;
      transform-origin: left center;
      &:last-child {
        width: 67%;
        margin-bottom: 0;
      }
    }
  }
  &.open {
    .nav-outer {
      background-color: transparent;
      background-image: none;
      box-shadow: none;
    }
    * {
      color: white;
    }
    .hamburger {
      span {
        background-color: white;
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: translateX(-3rem);
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          width: 100%;
        }
      }
    }
    svg {
      path {
        fill: white;
      }
    }
  }
  .white {
    * {
      color: white;
    }
    .hamburger {
      span {
        background-color: white;
      }
    }
    #Logo {
      path {
        fill: white;
      }
    }
  }
  &.inverse {
    .nav-outer {
      background: rgba(0,0,0,1);
      background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.78) 30%, rgba(0,0,0,0) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,1)), color-stop(30%, rgba(0,0,0,0.78)), color-stop(100%, rgba(0,0,0,0)));
      background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.78) 30%, rgba(0,0,0,0) 100%);
      background: -o-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.78) 30%, rgba(0,0,0,0) 100%);
      background: -ms-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.78) 30%, rgba(0,0,0,0) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.78) 30%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
    }
    * {
      color: white;
    }
    svg {
      path {
        fill: white;
      }
    }
    button.btn.btn-coral {
      border-color: white;
      background-color: white;
      color: black;
      &:hover {
        color: $coral;
        border-color: $coral;
      }
    }
    .hamburger-inner {
      span {
        background-color: white;
      }
    }
  }
  &.navbar-hidden {
    .nav-outer {
      transform: translateY(-100%);
    }
  }
  @media all and (min-width: 992px) and (max-width: 1339px) {
    .nav-item .nav-link {
      font-size: .8rem;
      padding: .5rem;
    }
    .logo svg {
      max-width: 5rem;
    }
    .btn.btn-coral, a.coral, .language {
      font-size: .8rem;
    }
    .btn.btn-coral {
      width: auto;
      min-width: 0;
      padding: 0 1rem;
    }
    .language {
      padding: 0;
    }
    .collapse.navbar-collapse {
      margin-left: .5rem;
    }
  }
  @media all and (min-width: 1200px) and (max-width: 1339px) {
    .nav-item .nav-link {
      font-size: .9rem;
    }
    .btn.btn-coral, a.coral, .language {
      font-size: .9rem;
    }
  }
}