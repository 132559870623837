.LogoBox {
  width: 100%;
  min-height: 12.5rem;
  border-radius: .75rem;
  //border: 2px solid #f2f3f5;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: white;
}