.Modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  .modal-wrapper {
    height: 100vh;
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
  }
  .modal-backdrop {
    background-color: #fafafa;
  }
  .modal-close-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: #fafafa;
    height: 3rem;
    z-index: 1041;
    .modal-close {
      width: 2rem;
      height: 2rem;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 1rem;
      top: .5rem;
      justify-content: space-between;
      z-index: 1100;
      cursor: pointer;
      span {
        width: 100%;
        height: .25rem;
        background-color: black;
        &:nth-child(1) {
          align-self: flex-start;
          transform: rotate(45deg);
          transform-origin: left bottom;
          margin-top: 3px;
        }
        &:nth-child(2) {
          align-self: flex-end;
          transform: rotate(-45deg);
          transform-origin: left top;
        }
      }
    }
  }
  .modal-content {
    background-color: white;
    z-index: 1041;
    //position: fixed;
    left: 0;
    top: 3rem;
    right: 0;
    bottom: 0;
    height: calc(100% - 3rem);
    overflow-y: auto;
  }
  &.closed {
    //display: none;
    animation: fadeOut .3s ease-out forwards;
  }
  &.open {
    display: block;
    animation: fadeIn .3s ease-out;
    z-index: 1031;
  }
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(10rem);
    z-index: -1;
  }
  1% {
    display: block;
    opacity: 0;
    transform: translateY(10rem);
    z-index: 1031;
  }
  100% {
    display: block;
    opacity: 1;
    transform: translateY(0);
    z-index: 1031;
  }
}

@keyframes fadeOut {
  0% {
    display: block;
    opacity: 1;
    transform: translateY(0);
    z-index: 1031;
  }
  99% {
    display: block;
    opacity: 0;
    transform: translateY(10rem);
    z-index: 1031;
  }
  100% {
    display: none;
    opacity: 0;
    transform: translateY(10rem);
    z-index: -1;
  }
}

@keyframes backdropAnimation {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes contentAnimation {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}