.Card {
  padding: 4rem;
  border-radius: .75rem;
  background-color: white;
  box-shadow: 6px 6px 20px rgba(137, 137, 137, 0.16);
  position: relative;
  .btn-container {
    position: relative;
    z-index: 1;
  }
  .dot-bg {
    background-position: top center;
    position: absolute;
    //width: 80%;
    //height: 80%;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);
    width: calc(100% - 8rem);
    height: calc(100% - 8rem);
  }
  p.introduction {
    opacity: 0.6;
  }
  .card-image {
    max-width: 6rem;
    margin-bottom: 5rem;
  }
}