.Footer {
  background-color: black;
  * {
    color: white;
  }
  svg path {
    fill: white;
  }
  a {
    //color: #050c1a;
    color: white;
    display: block;
    width: 100%;
    &:hover {
      color: white;
    }
  }
  .social {
    a {
      display: inline-block;
      margin-right: .5rem;
      width: auto;
    }
  }
  .privacy-policy {
    display: flex;
    flex-wrap: wrap;
    p, a {
      margin-right: 3rem;
      width: auto;
    }
  }
}