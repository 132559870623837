@import '../../stylesheets/bootstrap';
@import '../../stylesheets/variables';

.ContactForm {

  :not(output):-moz-ui-invalid {
    border: none;
    border-bottom: 2px solid rgba(5, 12, 26, 0.25);
  }
  .form-control {
    //border: 2px solid rgba(5, 12, 26, 0.25);
    min-height: 3rem;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid rgba(5, 12, 26, 0.25);
    padding-left: 0;
    padding-right: 0;
    &:focus, &:valid {
      border-color: $violet;
      //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: none;
    }
    &[contenteditable] {
      height: auto;
      max-height: 12rem;
      &:not(:empty) {
        border-color: $violet;
      }
    }
    &:invalid, &:-moz-ui-invalid {
      border-bottom: 2px solid $coral;
      box-shadow: none;
    }
    &.empty {
      border-color: rgba(5, 12, 26, 0.25);
    }
    &:focus {
      border-color: $violet;
      //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: none;
    }
    &.invalid {
      border-color: $coral;
    }
  }
  .form-check {
    padding-left: 0;
  }
  .custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked {
        &~.checkmark {
          border-color: $violet;
          &:after {
            opacity: 1;
          }
        }
      }
    }
    .checkmark {
      display: inline-block;
      background-color: white;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: .5rem;
      vertical-align: top;
      border: 2px solid rgba(5, 12, 26, 0.25);
      transition: all .3s ease;
      margin-right: 1rem;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        opacity: 0;
        display: block;
        width: .5rem;
        height: 1rem;
        border: solid $violet;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg) translateX(-50%);
        transition: all .3s ease;
        transform-origin: center center;
        left: 50%;
        top: 10%;
      }
      &.invalid {
        border-color: $coral;
      }
    }
    small {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 2.5rem);
      font-size: .625rem;
      line-height: 1.2;
      @include media-breakpoint-down(sm) {
        font-size: .8rem;
      }
    }
    &:hover {
      input ~ .checkmark {
        background-color: rgba(5, 12, 26, 0.1);
      }
    }
  }
}